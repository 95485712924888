@import '@ingka/variables/style';
.mapCard{
  display: flex;
  flex-wrap: nowrap;

  .image{
    width: 4rem;
    height: 4rem;
    border-radius: 0.4rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid $colour-neutral-3;
  }

  .content{
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h3{
      margin: 0;
    }
    p{
      margin: 0;
    }
    .data{
      display: flex;
      justify-content: space-between;
      .counter{
        display: flex;
        p:first-child{
          margin-right: 1rem;
        }
      }
    }
  }
}

.addMap{
  border: 1px solid $colour-static-grey;
  border-radius: 0.4rem;
  padding: 1rem;
  margin-top: 2rem;
  margin-bottom: 3rem;
}