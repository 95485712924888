@import '@ingka/variables/style';

.card{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 2rem;
  cursor: pointer;
  .box{
    background-color: $colour-static-light-grey;
    width: 7rem;
    height: 7rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}