@import '@ingka/variables/style';

.select{
  >:nth-child(3){
    border-radius: 4px;
    min-height: 48px;
    font-size: 1rem;
    line-height: 1.5;
    border-color: $colour-interactive-subtle-border-default;
  }
}
.label{
  font-size: 0.875rem;
  line-height: 1.571;
  margin: 0 0 0.125rem 0;
  color: $colour-text-and-icon-2;
}