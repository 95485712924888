@import '@ingka/variables/style';

.card{
  cursor: pointer;
  .body{
  text-align: center;
  .image{
    width: 100%;
    height: auto;

  }
}
}
