@import '@ingka/variables/style';
.data{
  display: flex;
  justify-content: space-between;
  h5, h4{
    margin-bottom: 0;
  }
}
.bar{
  height: 1.2rem;
  width: 100%;
  background-color: $colour-static-light-grey;
  border-radius: 50px;

  .filler{
    height: 100%;
    width: 0%;
    background: linear-gradient(90deg, rgba(0,88,163,1) 0%, rgba(44,179,255,1) 100%);
    border-radius: inherit;
    transition: width 1s ease-in-out;

    .label{
      
      font-weight: bold;
      display: flex;
      justify-content: end;
      align-items: center;
      height: 100%;
      font-family: "Noto IKEA", "Noto Sans", "Roboto", "Open Sans", system-ui, sans-serif !important;
      font-size: 0.9rem;
      &.right{
        margin-right: -2rem;
      }
      &.left{
        padding-right: 1rem;
        color: white;
      }
    }
  }
}



