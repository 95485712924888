@import '@ingka/variables/style';
$headerHeight: 4.4rem;
$widthConsole: 10rem;
$padding: 1.5rem;
$heightConsole: 4.625rem;

.container{
  width: 100%;
  height: 100%;
  display: flex;
  @media(orientation: landscape) { //horizontal orientation
    flex-direction: row;
    flex-wrap: nowrap;
  }
  @media(orientation: portrait) { //vertical orientation
    flex-direction: column-reverse;
    &.view_motions{
      flex-direction: column;
    }
  }
  .canvas{
    @media(orientation: landscape) { //horizontal orientation
      width: calc(100% - $widthConsole);
      height: calc(100vh - $padding - $headerHeight);
    }
    @media(orientation: portrait) { //vertical orientation
      width: 100%;
      height: calc(100vh - $heightConsole - $headerHeight);
    }
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .background{
      background-repeat: no-repeat;
    }
  }
  .console{
    display: flex;
    
    @media(orientation: landscape) { //horizontal orientation
      width: $widthConsole;
      height: calc(100vh - $padding - $headerHeight);
      flex-direction: column;
      justify-content: space-between;
      text-align: center;
      padding-left: $padding;
      padding-top: $padding;
    }
    @media(orientation: portrait) { //vertical orientation
      flex-direction: row;
      justify-content: space-between;
      max-height: $heightConsole;
      &.view_motions{
        max-height: none;
      }
      
      .polygonButtons{
        display: flex;
        #polygonButtons{
          display: flex;
        }
      }
      @media(max-width: $breakpoint-m){
        button[name="settingButton"]{
          display: none;
        }
      }
      
      .editButtons{
        display: flex;
        align-items: center;
        h4{
          margin-right: 1rem;
          @media(min-width: $breakpoint-m){
            margin-top: 0;
            margin-bottom: 0;
          }
          @media(max-width: $breakpoint-m){
            margin-top: 0.4rem;
            margin-bottom: 0;
            font-size: 0.75rem;
          }
        }
      }
      button {
        width: fit-content !important;
        @media(min-width: $breakpoint-m){
          margin-right: 1rem;
        }
        @media(max-width: $breakpoint-m){
          margin-right: 0.4rem;
        }
        > span {
              padding: 0 0.56rem !important;
          > span{
            display: none;
          }
        }
        svg{
          margin: 0;
        }
      }
    }
    

    button[type="button"]{
      width: 100%;
      margin-top: 0.6rem;
    }

    //MOTION
    .firstZone{
      color: $colour-static-ikea-brand-blue;
      @media(orientation: landscape) {
        .dataAction, .dataTime{
          display: flex;
          justify-content: center;
          align-items: center;
          
        }
      }
      @media(orientation: portrait) {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        margin-top: 0.6rem;
        .dataAction{
          display: none;
        }
        .dataTime{
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 0.4rem;
        }
        button{
          margin-top: 0;
        }
      }
      
      
      @media(min-width: $breakpoint-m){
        margin-bottom: 1rem;
      }
      @media(max-width: $breakpoint-m){
        margin-bottom: 0.4rem;
      }
  
      p{
        color: $colour-static-ikea-brand-blue;
        margin: 0;
        margin-left: 0.2rem;
      }
    }

    button[name="action"]{
      margin-bottom: 0.6rem;
    }
    @media(orientation: portrait) {
      
    }
    div[name="dualButton"]{
      margin-top: 0.6rem;
      button:first-child{
        margin-right: 0 !important;
      }
    }
  }
  
  .cardAction{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 1rem;
    cursor: pointer;
    width: 100%;
    justify-content: center;
    .boxAction{
      background-color: $colour-static-light-grey;
      height: 4rem;
      border-radius: 0.6rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 1rem;
      p{
        margin: 0
      }
      &.show{
        border: 2px solid $colour-elevation-2-border;
      }
    }
  }
  
}