@import '@ingka/variables/style';

.input{
  border-radius: 4px;
  height: 48px;
  font-size: 1rem;
  line-height: 1.5;
  border:1px solid $colour-interactive-subtle-border-default;
  width: 100%;
  color: $colour-text-and-icon-1;
  font-family:"Noto IKEA", "Noto Sans", "Roboto", "Open Sans", system-ui, sans-serif !important;
  &::-webkit-calendar-picker-indicator{
    font-size: 1.4rem;
  }
}

.label{
  font-size: 0.875rem;
  line-height: 1.571;
  margin: 0 0 0.125rem 0;
  color: $colour-text-and-icon-2;
}