@import '@ingka/variables/style';
.actionCard{
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  .icon{
    fill: $colour-text-and-icon-2;
    width: 24px;
    height: 24px;
  }

  .content{
    width: calc(100% - 4rem);
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h3{
      margin: 0;
    }
    p{
      margin: 0;
    }
    .switch{
      margin-top: 1rem;
    }
  }
}