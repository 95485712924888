@import '@ingka/variables/style';

.login{
    height: 100vh;
    background-color: $colour-static-ikea-brand-blue;
    position: relative;
    .brand{
        padding-top: $space-400;
        max-width: 500px;
        margin: 0 auto;
        height: 40%;
        .title{
            font-size: $font-size-1000;
            span:nth-child(1){
                color: $colour-static-ikea-brand-yellow;
            }
            span:nth-child(2){
                color: $colour-static-white;
            }
        }
        .subtitle{
            color: $colour-static-white;
        }
    }
    .form{
        margin-top: $space-400;
        background-color: $colour-static-light-grey;
        border-top-right-radius: 4rem;
        height: 60%;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        .form-container{
            max-width: 500px;
            margin: 0 auto;
            padding-top: 7rem;
            display: flex;
            flex-direction: column;
        }
    }
    .copy{
        position: absolute;
        bottom: 0.3rem;
        left: 0;
        width: 100%;
        text-align: center;
    }
}